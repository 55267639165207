import logo from './logo.svg';
import './App.css';
import LinearProgress from '@mui/material/LinearProgress';
import {Routes,Route} from 'react-router-dom'
import {useState,useEffect,React,Suspense,lazy} from "react"
import Stack from '@mui/material/Stack';
import { useDispatch,useSelector } from 'react-redux';
import axios from 'axios';
import {apicall} from './Redux/MiddlewareReduxThunk/AllderfermentWmiddlew'
import {sexamSchedule} from './Redux/MiddlewareReduxThunk/SexamScheduleRthunk'
import {allProgramthunk} from './Redux/MiddlewareReduxThunk/LoadprogramsRthunk'
// import setStudents from './Redux/Actions/studentAction'
import { ContextRecords } from './ContextAPI/ContextRecords';
import store from './Redux/Store/Store'

const History=lazy(()=> import('./components/History'))
const Attendance=lazy(()=> import('./components/Attendance'))

const Setting=lazy(()=> import('./components/Setting'))
const Login=lazy(()=> import('./components/Login'))
const Layout=lazy(()=> import('./components/Layout'))
const Createuser=lazy(()=> import('./components/Createuser'))
const AppBar=lazy(()=> import('./components/AppBar'))
const Home=lazy(()=> import('./components/Home'))
const Listofuser=lazy(()=> import('./components/Listofuser'))
const Bulkfile=lazy(()=> import('./components/Bulkfile'))
const Underhistory=lazy(()=> import('./components/Underhistory'))
const Centrestatistic=lazy(()=> import('./components/Centrestatistic'))
const Signout=lazy(()=> import('./components/Signout'))
const Filterissue=lazy(()=> import('./components/Filterissue'))
const Bulkprint=lazy(()=> import('./components/Bulkprint'))
const Courseattendance=lazy(()=> import('./components/Courseattendance'))
const Courseattence=lazy(()=> import('./components/Courseattence'))
const Defermentlist=lazy(()=> import('./components/Defermentlist'))
const Comfirmstatistic=lazy(()=> import('./components/Comfirmstatistic'))
const Tcler=lazy(()=> import('./components/Tcler'))
const Sofresultstranscript=lazy(()=> import('./components/Sofresultstranscript'))
const Rank=lazy(()=> import('./components/Rank'))
const Addinvigilator=lazy(()=> import('./components/Addinvigilator'))
const Mileage=lazy(()=> import('./components/Mileage'))
const Asigntask=lazy(()=> import('./components/Asigntask'))
const Studentprojectprog=lazy(()=> import('./components/Studentprojectprog'))
const PaymentReceipt=lazy(()=> import('./components/PaymentReceipt'))









function App() {
const [loadschdulr,setLoadschdulr]=useState([])

const [timetabled,setTimetabled]=useState([])
const [loaddeferment,setLoaddeferment]=useState([])
const [allLoaddeferment,setAllLoaddeferment]=useState([])
const [studentsall,setStudentsall]=useState([])
const [rankdata,setRankdata]=useState([]);
const [checkupdate,setCheckupdate]=useState(0);
const storein=localStorage.getItem("storejws");
const jabi=JSON.parse(storein);
console.log('jabi', jabi)
let whoisthis;
let username;
let uid;
   if(jabi!=null){
 whoisthis=jabi.privilage;
 username=jabi.username;
 uid=jabi.rule;
   }

const [recordsuser, setRecordsuser] = useState([]);


const [skeloton, setSkeloton] = useState(0);

const [showpost, setShowpost] = useState([]);
const [statcheck,setStatcheck]=useState(0)
const [pulsrecord,setPulsrecord]=useState([])
const [loadprog,setLoadprog]=useState([])

useEffect(()=>{

  //console.log("mubi",userid)
      axios.post(process.env.REACT_APP_UNIVERSAL_URL+"/getpostuser.php",{conter:100,filter:1,userid:uid}).then((result)=>{
    
  
           if(result.data.length !=0){
            setShowpost(result.data)
        
            setSkeloton(1)
           } else{ 
           setSkeloton(1)
            // console.log("Soja   11:", result.data)
           }
        
      
      }).then((error)=>{
      //error
      
      
      })
      
      
      //alert(showpost2)
      
  },[])
useEffect(()=>{

  
  
  axios.get(process.env.REACT_APP_UNIVERSAL_URL+"/getRanks.php").then((result)=>{
     console.log("Arewa:::",result)
      if(result.data.length>=1){
        setRankdata(result.data)
        setCheckupdate(0)
      }
   });  

},[checkupdate])
  useEffect(()=>{  
    store.dispatch(apicall());
    store.dispatch(sexamSchedule())
    store.dispatch(allProgramthunk())



  //  axios.get(process.env.REACT_APP_UNIVERSAL_URL+"/loadprog.php").then((result)=>{
  //   //console.log("ram Niger2:",result)
  //   if(result.data.length>=1){
          
  //           setLoadprog(result.data)
  //        // console.log("jakoli:::",result.data)
  //       } else{

        
  //       }
  //    });


     axios.get(process.env.REACT_APP_UNIVERSAL_URL+"/Timetableload.php").then((result)=>{
      //  console.log("ram Niger21:",result)
        if(result.data.length>=1){
              
          setTimetabled(result.data)
             // console.log("jakoli:::",result.data)
            } else{
    
            
            }
         });


        //  axios.get(process.env.REACT_APP_UNIVERSAL_URL+"/loaddeferments.php").then((result)=>{
        //   //  console.log("Bukar asali:",result)
        //     if(result.data.length>=1){
                  
        //       setLoaddeferment(result.data)
        //          // console.log("jakoli:::",result.data)
        //         } else{
        
                
        //         }
        //      });


 


    },[])

    const seethestore=useSelector(state=>state)
    const [invigilator,setInvigilator]=useState([]);
    const [loadstate,setLoadstate]=useState([]);
    const [mileage,setMileage]=useState([]);
    const [projectlist,setProjectlist]=useState([]);
    // console.log("See the thunk2",seethestore)
    useEffect(()=>{

    //  console.log("See the thunk",seethestore)

      // axios.get(process.env.REACT_APP_UNIVERSAL_URL+"/loadallderferment.php").then((result)=>{
      //   console.log("all Bukar asali1111:",result)
      //    if(result.data.length>=1){
               
      //      setAllLoaddeferment(result.data)
      //         // console.log("jakoli:::",result.data)
      //        } else{
     
             
      //        }
      //     });


          

          // axios.get(process.env.REACT_APP_UNIVERSAL_URL+"/loadstu.php").then((result)=>{
          //   console.log("students:",result)
          //    if(result.data.length>=1){
                   
          //     setStudentsall(result.data)
          //         // console.log("jakoli:::",result.data)
          //        } else{
         
                 
          //        }
          //     });



        axios.get(process.env.REACT_APP_UNIVERSAL_URL+"/loadinvigilator.php").then((result)=>{
        // console.log("invigilator kai nas!!!:",result)
         if(result.data.length>=1){
          setInvigilator(result.data)
          //  setAllLoaddeferment(result.data)
              // console.log("jakoli:::",result.data)
             } else{
     
             
             }
          });



          axios.get(process.env.REACT_APP_UNIVERSAL_URL+"/Stateobj.php").then((result)=>{
            //  console.log("load state!!!:",result)
             if(result.data.length>=1){
              setLoadstate(result.data)
                 } else{
         
                 
                 }
              });



              axios.get(process.env.REACT_APP_UNIVERSAL_URL+"/loadmailege.php").then((result)=>{
                console.log("load loadmailage!!!:",result)
                 if(result.data.length>=1){
                  setMileage(result.data)
                     } else{
             
                     
                     }
                  });

    },[checkupdate])
    const dispatch=useDispatch();      

 // setStudentSched
// console.log("rajas1",loadschdulr)
//console.log("rajas2",timetabled)
// dispatch(setStudentSched(loadschdulr))
// dispatch(setProgram(loadprog))
// dispatch(setTimetable(timetabled))
// dispatch(setDeferment(loaddeferment))
//dispatch(setAlldefermentAction(allLoaddeferment))
//dispatch(setStudents(studentsall))

// console.log('alobadi', rankdata)



useEffect(()=>{
     
  if(whoisthis==1){  
axios.get(process.env.REACT_APP_UNIVERSAL_URL+"/loadprog.php").then((result)=>{
    // console.log("Programs nas:",result.data.abbr)
  
  //  setLoadprogram(result.data)
    if(result.data.length>0){
      setLoadprog(result.data)
 
    }else{


      // alert("Records not found!")
      //setLetseethem('Record not found!')
  //    navigate('/');
    }

});

}else if(whoisthis==2){

axios.post(process.env.REACT_APP_UNIVERSAL_URL+"/loadprogbutduties.php",{email:username }).then((result)=>{
  //  console.log("Programs nas111:",result.data)

//  setLoadprogram(result.data)
  if(result.data.length>0){
    setLoadprog(result.data)

  }else{


    // alert("Records not found!")
    //setLetseethem('Record not found!')
//    navigate('/');
  }

});
}

setStatcheck(1)
axios.get(process.env.REACT_APP_UNIVERSAL_URL+"/loadstatusadmin.php").then((result)=>{
 // console.log("zali",result)
//    let fname=result.data[0].fname;
setPulsrecord(result.data)
setStatcheck(0)

 });
},[])

const [loadmaster,setLoadmaster]=useState(0);
const [loadsuper,setLoadsuper]=useState([]);
const [students,setStudents]=useState([]);

useEffect(()=>{

  axios.post(process.env.REACT_APP_UNIVERSAL_URL+"/loadsupervisor.php",{username:username}).then((result)=>{
    // console.log("loadsupervisor:",result.data)
  
  //  setLoadprogram(result.data)
    if(result.data.length>0){
      setLoadsuper(result.data)
 
    }else{


      alert("Records not found!")
      //setLetseethem('Record not found!')
  //    navigate('/');
    }

});



axios.get(process.env.REACT_APP_UNIVERSAL_URL+"/projectlist.php").then((result)=>{
  // console.log("loadsupervisor:",result.data)

//  setLoadprogram(result.data)
 if(result.data.length>0){
   //  setLoadsuper(result.data)
   setProjectlist(result.data)
 }else{


  //  alert("Records not found!")
   //setLetseethem('Record not found!')
//    navigate('/');
 }

});
},[loadmaster])

useEffect(()=>{
  axios.post(process.env.REACT_APP_UNIVERSAL_URL+"/loadstu2.php",{username:username}).then((result)=>{
    //  console.log("zali222",result)
   //    let fname=result.data[0].fname;
   setStudents(result.data) 
   
   
    });

 
},[])
  return (
<>


<ContextRecords.Provider  value={{projectlist,students,loadsuper,setLoadmaster,loadprog,pulsrecord,statcheck,rankdata,setCheckupdate,invigilator,loadstate,mileage,showpost,skeloton}}>

{/* <Rank /> */}

<Routes>







{/* <ContextRecords.Provider > */}

<Route path="/rank"  element={ <Suspense fallback={<div> <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress color="secondary" />
      <LinearProgress color="success" />
      <LinearProgress color="inherit" />
    </Stack></div>} ><Rank /></Suspense>}  />
    
    
    

    <Route path="/studentprojectprog"  element={ <Suspense fallback={<div> <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress color="secondary" />
      <LinearProgress color="success" />
      <LinearProgress color="inherit" />
    </Stack></div>} ><Studentprojectprog /></Suspense>}  />
    
<Route path="/asigntask"  element={ <Suspense fallback={<div> <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress color="secondary" />
      <LinearProgress color="success" />
      <LinearProgress color="inherit" />
    </Stack></div>} ><Asigntask /></Suspense>}  />
    <Route path="/mileage"  element={ <Suspense fallback={<div> <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress color="secondary" />
      <LinearProgress color="success" />
      <LinearProgress color="inherit" />
    </Stack></div>} ><Mileage /></Suspense>}  />
 

    
    
    <Route path="/addinvigilator"  element={ <Suspense fallback={<div> <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress color="secondary" />
      <LinearProgress color="success" />
      <LinearProgress color="inherit" />
    </Stack></div>} ><Addinvigilator /></Suspense>}  />

    
<Route path="/comfirmstatistic/:centre"  element={ <Suspense fallback={<div> <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress color="secondary" />
      <LinearProgress color="success" />
      <LinearProgress color="inherit" />
    </Stack></div>} ><Comfirmstatistic /></Suspense>}  />

    
    <Route path="/sofresultstranscript"  element={ <Suspense fallback={<div> <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress color="secondary" />
      <LinearProgress color="success" />
      <LinearProgress color="inherit" />
    </Stack></div>} ><Sofresultstranscript /></Suspense>}  />

    <Route path="/"  element={ <Suspense fallback={<div> <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
    <LinearProgress color="secondary" />
    <LinearProgress color="success" />
    <LinearProgress color="inherit" />
  </Stack></div>} ><Login /></Suspense>}  />

  <Route path="/secondoption/:regNo/:rule/:tran"  element={ <Suspense fallback={<div> <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
    <LinearProgress color="secondary" />
    <LinearProgress color="success" />
    <LinearProgress color="inherit" />
  </Stack></div>} ><Tcler /></Suspense>}  />

  <Route path="/payment-receipt/:regno" element={<PaymentReceipt />} />


  

  <Route path="/courseattendance/:course/:centre/:myprogramsee/:wdate"  element={ <Suspense fallback={<div> <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress color="secondary" />
      <LinearProgress color="success" />
      <LinearProgress color="inherit" />
    </Stack></div>} ><Courseattendance /></Suspense>}  />

  <Route path="/bulkprint/:dateid/:centreid/"  element={ <Suspense fallback={<div> <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
    <LinearProgress color="secondary" />
    <LinearProgress color="success" />
    <LinearProgress color="inherit" />
  </Stack></div>} ><Bulkprint /></Suspense>}  />




  <Route path="/hadmin"  element={ <Suspense fallback={<div> <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress color="secondary" />
      <LinearProgress color="success" />
      <LinearProgress color="inherit" />
    </Stack></div>} ><Home /></Suspense>}  />

    

    <Route path="/defermentlist"  element={ <Suspense fallback={<div> <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress color="secondary" />
      <LinearProgress color="success" />
      <LinearProgress color="inherit" />
    </Stack></div>} ><Defermentlist /></Suspense>}  />


    <Route path="/courseattence"  element={ <Suspense fallback={<div> <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress color="secondary" />
      <LinearProgress color="success" />
      <LinearProgress color="inherit" />
    </Stack></div>} ><Courseattence /></Suspense>}  />



    <Route path="/filterissue/:idissue"  element={ <Suspense fallback={<div> <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress color="secondary" />
      <LinearProgress color="success" />
      <LinearProgress color="inherit" />
    </Stack></div>} ><Filterissue /></Suspense>}  />

  <Route path="/setting"  element={ <Suspense fallback={<div> <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
         <LinearProgress color="secondary" />
         <LinearProgress color="success" />
         <LinearProgress color="inherit" />
       </Stack></div>} ><Setting /></Suspense>}  />

       <Route path="createuser"  element={ <Suspense fallback={<div> <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
         <LinearProgress color="secondary" />
         <LinearProgress color="success" />
         <LinearProgress color="inherit" />
       </Stack></div>} ><Createuser /></Suspense>}  />
       <Route path="/listofuser"  element={ <Suspense fallback={<div> <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
         <LinearProgress color="secondary" />
         <LinearProgress color="success" />
         <LinearProgress color="inherit" />
       </Stack></div>} ><Listofuser /></Suspense>}  />
   
       <Route path="/bulkfile"  element={ <Suspense fallback={<div> <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
         <LinearProgress color="secondary" />
         <LinearProgress color="success" />
         <LinearProgress color="inherit" />
       </Stack></div>} ><Bulkfile /></Suspense>}  />
       <Route path="/history"  element={ <Suspense fallback={<div> <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
         <LinearProgress color="secondary" />
         <LinearProgress color="success" />
         <LinearProgress color="inherit" />
       </Stack></div>} ><History /></Suspense>}  />
       <Route path="/underhistory"  element={ <Suspense fallback={<div> <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
         <LinearProgress color="secondary" />
         <LinearProgress color="success" />
         <LinearProgress color="inherit" />
       </Stack></div>} ><Underhistory /></Suspense>}  />





       <Route path="/attendance"  element={ <Suspense fallback={<div> <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
         <LinearProgress color="secondary" />
         <LinearProgress color="success" />
         <LinearProgress color="inherit" />
       </Stack></div>} ><Attendance /></Suspense>}  />

       <Route path="/centrestatistic"  element={ <Suspense fallback={<div> <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
         <LinearProgress color="secondary" />
         <LinearProgress color="success" />
         <LinearProgress color="inherit" />
       </Stack></div>} ><Centrestatistic /></Suspense>}  />
       <Route path="signout"  element={ <Suspense fallback={<div> <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
         <LinearProgress color="secondary" />
         <LinearProgress color="success" />
         <LinearProgress color="inherit" />
       </Stack></div>} ><Signout /></Suspense>}  />
   
   

</Routes>
</ContextRecords.Provider>
</>
  );
}

export default App;
